const Feesten = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                <div className="footer-info-box text-center">
                    <h1 className="title">Onze Feestfolder</h1>
                    <p>
                        <br />
                        Beste klanten, Hier is hij dan!
                        <br />
                        Onze feestfolder!
                        <br />
                        <br />
                        Dit jaar vragen wij om deze enkel in de winkel af te
                        geven.
                        <br />
                        Klanten die vooraf betalen zullen hun bestelling sneller
                        en makkelijker kunnen afhalen.
                        <br />
                        <br />
                        <h2>Bestellen:</h2>
                        <br />
                        Voor kerst bestellen mogelijk tot 19 december.
                        <br />
                        Afhalen op 24 December tussen 10 & 14 uur.
                        <br />
                        <br />
                        Voor Oud & Nieuw bestellen mogelijk tot 26 december.
                        <br />
                        Afhalen op 31 December tussen 10 & 14 uur.
                        <br />
                        <br />
                        <a
                            style={{ fontSize: "30px" }}
                            href="https://hoeveslagerijsmismans.be/flyer-nl.pdf"
                        >
                            Folder NL
                        </a>
                        <br />
                        <a
                            style={{ fontSize: "30px" }}
                            href="https://hoeveslagerijsmismans.be/flyer-fr.pdf"
                        >
                            Folder FR
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Feesten;
